.tabs {
  @include flex(column);

  height: 100%;

  ul {
    @include list-reset;
    @include flex-align(flex-start, space-between);

    flex-shrink: 0;
    width: 100%;
  }

  .tab {
    border: 1px solid $border-color;
    border-width: 0 1px 1px 0;
    flex-grow: 1;
    min-width: 50%;
  }

  .tab:last-child {
    border-right: 0;
  }

  .tab-active {
    border-bottom: 0;
  }

  .tab-active:only-child {
    border-bottom: 1px solid $border-color;
  }

  .tab-title {
    @include header($type-subheader);

    background: $border-color;
    display: block;
    padding: $baseline-height;
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    i {
      margin-right: 10px;
    }
  }

  .tab-title {
    color: $primary-color;
    font-weight: bold;
    cursor: pointer;
  }

  .tab-active .tab-title {
    background: $action-bg;
    cursor: auto;
  }
}

.tab-content {
  height: 100%;
  overflow-y: auto;
  position: relative;
  max-height: 80vh;

  .tab-content-container {
    @include size(100%);

    left: 0;
    padding: padding(2);
    position: absolute;
    top: 0;

    &--no-padding {
      padding: 0;
    }
  }
}
