.email-exists-message {
  &-text, &-button {
    display: block;
    padding: baseline(1);
    font-size: $type-body;
    flex-grow: 0;
    flex-shrink: 1;
    text-align: center;
    width: 75%;
    margin: 0 auto;
  }

  &-text {
    @include colorize(error);
    margin-bottom: baseline();
    border-radius: 5px;
    margin-top: baseline(1);

    &::before {
      @include arrow(up, baseline(1), get-message-bg($colors-error));

      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-button {
    @include pill;
    @include colorize(success);

    font-weight: bold;
    width: 50%;
    transition: .2s background;

    &:hover {
      cursor: pointer;
      background: darken(get-message-bg($colors-success), 2%);
      transition: .2s background;
    }
  }

  &-bg {
    position: absolute;
    background: rgba(#fff, .75);
    bottom: baseline(-2);
    top: baseline(-1);
    right: baseline(-2);
    left: baseline(-2);
    z-index: 500;
    transition: opacity 0.2s;

    &.ng-enter {
      opacity: 0;

      &.ng-enter-active {
        opacity: 1;
      }
    }

    &.ng-leave {
      opacity: 1;

      &.ng-leave-active {
        opacity: 0;
      }
    }
  }

  &-container {
    position: relative;
  }

  &-disabled-content {
    transition: filter 0.5s;

    &.is-disabled {
      filter: blur(2px);
    }
  }

  &-content {
    text-shadow: 0 0 20px #fff;
  }
}
