@import '../css/main.css';

// Legacy SASS
@import 'legacy/main';

// Settings
@import 'settings/color';
@import 'settings/dimensions';

// Abstract
@import 'abstracts/mixins';
@import 'abstracts/functions';
@import 'abstracts/classes';

@import 'base';

// Animations
@import 'animations/spin';
@import 'animations/grow';
@import 'animations/bounce';

// Layout Components
@import 'layout/nav-bar';
@import 'layout/header';
@import 'layout/container';

// Typography
@import 'typography/baseline';

// Base Components
@import 'components/section';
@import 'components/link';
@import 'components/button';

// Specialised Components
@import 'components/treatment-summary';
@import 'components/success-overlay';
@import 'components/contact-table';
@import 'components/notice-text';
@import 'components/pricing-summary';
@import 'components/tabs';
@import 'components/note';
@import 'components/datepicker';
@import 'components/options';
@import 'components/form';
@import 'components/dropdown';
@import 'components/pdf';
@import 'components/details-box';
@import 'components/info-list';
@import 'components/message';
@import 'components/pop-over';
@import 'components/server-error';
@import 'components/deprecation-notice';
@import 'components/countdown-timer';
@import 'components/gdpr-checkbox';
@import 'components/tx-values';
@import 'components/search-bar';
@import 'components/toggle-field';
@import 'components/subsection';
@import 'components/email-exists-message';
@import 'components/alert';
@import 'components/field-group';
@import 'components/bottom-message';
@import 'components/entity-action-list';
@import 'components/color-field';
@import 'components/type';
@import 'components/rescheduler';
@import 'components/step-container';
@import 'components/modal';
@import 'components/comm-modal';
@import 'components/info-tags';

