.form-date-field {
  ._720kb-datepicker-calendar {
    margin-left: 0;
    position: static;
    transition: opacity 1s;
    opacity: 0;
    height: 0;
    padding: 0;
    &._720kb-datepicker-open {
      margin-top: $baseline-height;
      opacity: 1;
      height: auto;
      margin-left: -.5%;
      padding: 0 0 2% 0;
    }
  }

  ._720kb-datepicker-calendar-header:nth-child(odd) {
    background-color: $primary-color;
  }

  ._720kb-datepicker-calendar-day {
    border-radius: 3px;
    border: 1px solid $border-color;
    border-bottom: 3px solid $border-color;
  }

  ._720kb-datepicker-today {
    background: lighten($primary-color, 10%);
    color: $action-bg;
    border-bottom-color: $primary-color;
  }

  ._720kb-datepicker-active {
    background: $primary-color;
    color: $action-bg;
    border: 1px solid $primary-color;
    border-top: 3px solid rgba(0,0,0,0.1);
  }

  ._720kb-datepicker-disabled {
    border-bottom: 1px solid $border-color;
    transform: translateY(3px);
  }
}
