.link {
  @include baseline;
  @include body;
  @include hover-type($primary-color);

  display: inline-block;
  font-weight: bold;

  i {
    margin-right: baseline(.5);
  }
}
