.entity-action-list {
  &-item {
    display: block;
    color: $primary-color;
    padding: $baseline-height/2 0;

    &:hover {
      color: darken($primary-color, 10%);
    }
  }
}
