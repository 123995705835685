.page-dashboard .pipeline-stage {
  margin-bottom: 0;
}

.progress-bar {
  transition: width 1s ease-out;
}

.progress-bar-success {
  background: #75859b;
}

pipeline-stage .color-success {
  color: #75859b;
}
