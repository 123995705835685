.search-bar {
  margin: 39px 0 baseline(1);
  position: relative;

  &-input-container {
    position: relative;

    &::before {
      @include icon('\f1c3');

      align-items: center;
      bottom: 0;
      color: $non-primary-icon;
      display: flex;
      font-family: 'Material-Design-Iconic-Font', system-ui;
      font-size: baseline(1.18);
      left: 26px;
      line-height: 0;
      top: 0;
    }
  }

  &-input {
    @extend %form-field;

    border-radius: 6px;
    margin-bottom: 0;
    padding: 18px 18px 18px 66px;

    &.is-results {
      border-radius: 5px 5px 0 0;
    }
  }

  &-results {
    background: $search-bg;
    border-radius: 0 0 5px 5px;
    color: $search-color;
    position: absolute;
    width: 100%;
    z-index: 500;

    &-icon {
      font-size: baseline(1);
      margin-right: baseline(.5);
    }

    &-text {
      font-size: $type-body;
      font-style: italic;
      font-weight: 300;
    }

    &-name {
      font-weight: bold;
      margin-right: baseline(.5);
    }

    &-email {
      font-style: italic;
    }
  }

  &-loading,
  &-no-results {
    align-items: center;
    color: rgba($search-color, .5);
    display: flex;
    padding: baseline(1);
  }

  &-results-list {
    max-height: 50vh;
    overflow-y: auto;

    &-item {
      align-items: center;
      display: flex;
      font-size: $type-body;
      padding: baseline(1);
      transition: .15s background;

      &:nth-child(odd) {
        background: lighten($search-bg, 5%);
      }

      &:hover {
        background: $primary-color;
        cursor: pointer;
        transition: .15s background;
      }
    }
  }

  .button-add-patient {
    border-radius: 0 0 5px 5px;
    justify-content: flex-start;
    padding: baseline(1);

    &:hover {
      cursor: pointer;
    }
  }

  &-cancel {
    align-items: center;
    bottom: 0;
    display: flex;
    position: absolute;
    right: 18px;
    top: 0;
    transition: .2s color;

    &-icon {
      font-size: baseline(1);
    }

    &:hover {
      color: $primary-color;
      cursor: pointer;
      transition: .2s color;
    }
  }


}
