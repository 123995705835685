.message-warning {
  @include box($warning-color, $warning-bg);
}

.message-error {
  @include box($color-error, lighten($color-error, 50%));
}

.message-success {
  $colors: get-message-colors(success);
  @include rounded-box(get-message-bg($colors));
  color: get-message-color($colors);
  margin-bottom: baseline();
  font-size: $type-body;
}
