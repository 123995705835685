// Effects

@mixin bevel($depth, $bg-color, $shadow: 15%) {
  background: $bg-color;
  box-shadow: 0 $depth 0 darken($bg-color, $shadow);
}

@mixin pressable($depth, $bg-color, $shadow: 15%) {
  @include bevel($depth, $bg-color, $shadow);

  transition: .2s box-shadow, .2s transform;
  position: relative;

  &:hover {
    box-shadow: none;
    cursor: pointer;
    text-decoration: none;
    transform: translateY($depth);
  }
}

@mixin disable-pressable($depth, $bg-color, $shadow: 15%) {
  &:hover {
    box-shadow: 0 $depth 0 darken($bg-color, $shadow);
    cursor: default;
    transform: none;
  }
}

@mixin clear-pressable {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

// Positioning

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@mixin block-center($width) {
  margin: 0 auto;
  width: $width;
}

@mixin flex-align($pos, $justify: false) {
  display: flex;
  align-items: $pos;

  @if not $justify == false {
    justify-content: $justify;
  }
}

@mixin flex-center($justify: false) {
  @if $justify == justify {
    @include flex-align(center, center)
  }

  @else {
    @include flex-align(center)
  }
}

@mixin flex($dir: row) {
  display: flex;
  flex-direction: $dir;
}

// Shapes

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin circle($radius) {
  border-radius: $radius;
  @include size($radius);
}

@mixin pill($padding: $baseline-height) {
  border-radius: $padding + $baseline-height / 2;
}

@mixin box($color, $bg: $color, $padding: $baseline-height) {
  background-color: $bg;
  padding: $padding;

  @if $color != $bg {
    border: 1px solid $color;
  }
}

@mixin rounded-box($color, $bg: $color, $padding: $baseline-height) {
  @include box($color, $bg, $padding);

  border-radius: $border-radius;
}

@mixin line($color) {
  border-bottom: 1px solid $color;
  padding-bottom: $baseline-height;
  margin-bottom: $baseline-height;
}

$arrows: (
  up: bottom left right,
  down: top left right,
  left: right bottom top,
  right: left bottom top
);

@mixin arrow($dir, $size, $color) {
  $borders: map-get($arrows, $dir);

  @include -arrow-side(nth($borders, 1), $size, $color);
  @include -arrow-side(nth($borders, 2), $size, transparent);
  @include -arrow-side(nth($borders, 3), $size, transparent);

  width: 0;
  height: 0;
}

@mixin -arrow-side($dir, $size, $color) {
  border-#{$dir}: $size solid $color;
}

// Typography

@mixin baseline {
  margin-bottom: $baseline-height;
  line-height: 1.5;
}

@mixin header($size, $weight: 300) {
  color: $header-color;
  font-size: $size;
  font-weight: $weight;
}

@mixin body {
  font-size: $type-body;
  font-weight: normal;
  line-height: $baseline-height;
}

@mixin hover-type($color: inherit, $hover: $color) {
  color: $color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $hover;
    cursor: pointer;
    text-decoration: none;
  }
}

// Icon

@mixin icon($icon-code) {
  font-family: FontAwesome, system-ui;
  content: $icon-code;
  position: absolute;
}

@mixin material-icon($icon-code) {
  content: $icon-code;
  font-family: 'Material-Design-Iconic-Font', system-ui;
}

// Forms

@mixin icon-field($icon-code, $field: '.text-field') {
  position: relative;
  margin-bottom: $baseline-height;

  &::before {
    @include icon($icon-code);

    color: $primary-color;
    font-size: $type-body;
    left: $baseline-height;
    top: 15px;
  }

  #{$field} {
    margin-bottom: 0;
    text-indent: baseline(2);
  }
}

// Lists

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;

  li:last-child {
    margin-bottom: 0;
  }
}

// Message Colors

@mixin colorize($type: default) {
  $colors: get-message-colors($type);
  background: get-message-bg($colors);
  color: get-message-color($colors);
}

// Popover

@mixin popover($class, $bg, $color) {
  .popover-#{$class} {
    color: $color;
    background-color: $bg;

    &::after {
      @include arrow(down, 10px, $bg);
    }
  }
}
