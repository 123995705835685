.container {
  margin: 36px auto 0;

  &-thin {
    width: 750px;
  }

  &-full {
    width: auto;
  }
}
