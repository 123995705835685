.treatment-value-aggregate {
  .panel-box {
    .panel-bottom, .panel-top {
      height: 65px;
    }

    .panel-bottom {
      font-size: 24px;
      padding-top: 22px;
    }

    .panel-top {
      padding-top: 8px;
    }
  }
}

.logo-icon {
  text-align: center;

  img {
    vertical-align: bottom;
  }

  .logo-icon-big {
    animation: spin 6s linear infinite;
    width: 59%;
    opacity: 0.1;
  }

  .logo-icon-small-container {
    margin-left: -10px;
  }

  .logo-icon-small {
    animation: spin-clockwise 3s linear infinite;
    width: 31%;
    opacity: 0.25;
  }
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin-clockwise {
  100% {
    transform: rotate(360deg);
  }
}
