md-backdrop.md-dialog-backdrop {
  z-index: 2999;
}

.md-dialog-container {
  z-index: 3000;
}

.md-dialog-container md-input-container .md-errors-spacer {
  min-height: 0;
}

md-dialog .button-bar {
  position: relative;
  overflow: visible;
}

#patient-form-dialog .panel-footer {
  position: relative;
}

#patient-form-container.ng-enter {
  animation: slideInDown 0.5s;
}

#patient-form-container.ng-leave {
  animation: zoomOut 0.5s;
}

.md-dialog-container .md-toolbar-tools .md-button.md-icon-button {
  font-size: 2em;
  width: auto;
  height: auto;
}

.md-dialog-container,
md-dialog md-dialog-content,
md-dialog>form,
md-dialog {
  overflow: visible;
}
