@mixin slide-button($dir, $gradient-width: 80px) {
  @include flex-align(center, center);

  background: #fff;
  height: 100%;
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 10;
  #{$dir}: 0;

  &:hover {
    cursor: pointer;
  }

  &::after {
    background: linear-gradient(dir-to-turn($dir), #fff, transparent);
    content: '';
    height: 100%;
    position: absolute;
    width: $gradient-width;
    #{dir-flip($dir)}: -$gradient-width;
  }
}

.info-list {
  margin-bottom: baseline(2);
  overflow: hidden;
  position: relative;

  &-pane-wrapper, &-pane {
    @include flex-align(flex-start);
  }

  &-pane-wrapper {
    transition: .25s transform;
  }

  &-item {
    @include rounded-box($primary-color, $primary-color, padding(.5));

    color: $primary-button-color;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 10px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }

  &-icon {
    margin-right: 5px;
  }

  &-slide-right {
    @include slide-button(right);
  }

  &-slide-left {
    @include slide-button(left);
  }
}
