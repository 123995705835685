::selection {
  background: $primary-color;
}

.back-to-top {
  @include circle(baseline(2));
  @include pressable(3px, $primary-color);

  position: fixed;
  right: baseline(1);
  bottom: baseline(1);
  background: $primary-color;
  color: $primary-button-color;
  font-size: baseline(2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .6s transform;
  z-index: 2;

  &.ng-hide-remove {
    transform: scale(0.01);

    &.ng-hide-remove-active {
      transform: scale(1);
    }
  }

  &.ng-hide-add {
    transform: scale(1);

    &.ng-hide-add-active {
      transform: scale(0.01);
    }
  }
}
