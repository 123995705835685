@keyframes grow-top {
  from {
    transform: scale(0);
    transform-origin: top center;
  }

  to {
    transform: scale(1);
    transform-origin: top center;
  }
}
