.bottom-message {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  padding: ($baseline-height / 2) $baseline-height;
  display: flex;
  align-items: center;
  margin-left: 221px;
  font-size: $type-subheader;
  width: calc(100vw - 221px);
  transition: transform 0.5s;
  transform-origin: bottom;

  &.ng-enter, &.ng-leave.ng-leave-active {
    transform: scaleY(0.1);
    transition: transform 0.5s;
  }

  &.ng-leave, &.ng-enter.ng-enter-active {
    transform: scaleY(1);
    transition: transform 0.5s;
  }

  &::after {
    content: '';
    background: rgba(255, 255, 255, 0.5);
    animation: width-in 5s;
    position: absolute;
    bottom: 0;
    height: 5px;
    left: 0;
  }

  &-success {
    @include colorize(success);
  }

  &-error {
    @include colorize(error);
  }

  &-warning {
    @include colorize(warning);
  }

  &-content {
    flex: 1;
  }

  &-button {
    font-size: $type-header;
    line-height: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

@keyframes width-in {
  from {
    width: 0;
  }

  to {
    width: calc(100vw - 221px);
  }
}
