.pdf-view-dialog {
  position: relative;
  overflow-y: scroll;
  overflow-x: visible;
}

.pdf-view-controls {
  position: sticky;
  top: 0;

  .loading-spinner {
    @include horizontal-center;

    top: $baseline-height;
  }

  button {
    @include flex-center;

    background: rgba($header-color, .5);
    border: 0;
    border-radius: $border-radius;
    color: $primary-button-color;
    font-size: $baseline-height;
    padding: baseline(.5);
    position: absolute;
    top: $baseline-height;
    transition: opacity .5s, background .5s;

    &:hover {
      background: rgba($header-color, 1);
    }

    &.pdf-back span {
      margin-left: 10px;
    }

    &.pdf-next span {
      margin-right: 10px;
    }

    &.ng-enter {
      opacity: 0;

      &.ng-enter-active {
        opacity: 1;
      }
    }

    &.ng-leave {
      opacity: 1;

      &.ng-leave-active {
        opacity: 0;
      }
    }
  }

  .pdf-next {
    right: $baseline-height;
  }

  .pdf-back {
    left: $baseline-height;
  }
}
