.step-container {
  position: relative;
  transition: .6s transform, .4s opacity;
  opacity: 1;
  width: 100%;

  &.ng-enter {
    opacity: 0;
    position: relative;

    &.ng-enter-active {
      opacity: 1;
    }
  }

  &.ng-leave {
    position: absolute;
    opacity: 1;
    top: 54px;

    &.ng-leave-active {
      opacity: 0;
    }
  }

  // Forward
  &.ng-leave:first-child {
    transform: translateX(0);

    &.ng-leave-active {
      transform: translateX(-100%);
    }
  }

  &.ng-leave + .ng-enter {
    transform: translateX(100%);

    &.ng-enter-active {
      transform: translateX(0);
    }
  }

  // Backward
  &.ng-enter:first-child {
    transform: translateX(-100%);

    &.ng-enter-active {
      transform: translateX(0);
    }
  }

  &.ng-enter + .ng-leave {
    transform: translateX(0);

    &.ng-leave-active {
      transform: translateX(100%);
    }
  }
}
