.deprecation-notice {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 1.5em;

  &-callout {
    font-size: 27px;
    margin: 0 0 1em;
  }

  p, ul {
    margin-bottom: baseline();
  }
}
