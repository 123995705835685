.modal-open {
    overflow: hidden;
}

.modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    overflow: auto;
    cursor: initial;

    &__box {
        background-color: white;
        position: relative;
        top: 10vh;
        left: 50%;
        transform: translatex(-50%);
        border-radius: 10px;
        width: 40vw;
        min-width: 70rem;
        max-width: 100rem;
        margin-bottom: 20vh;
        cursor: initial;
    }

    &__close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        color: rgba(255, 255, 255, 0.35);
        font-size: 3rem;
        cursor: pointer;
        transition: all 300ms ease;

        &:hover {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}
