.popover-container {
  position: relative;

  .v2-popover {
    z-index: 500;
  }
}

.v2-popover {
  $colors: get-message-colors();
  $popover-bg: nth($colors, 1);
  $popover-color: nth($colors, 2);

  @include rounded-box($popover-bg, $popover-bg, $popover-padding);

  color: $popover-color;
  position: absolute;
  right: 0;
  top: -50px;
  white-space: nowrap;
  box-shadow: 2px 2px 20px rgba($shadow-color, 0.25);

  &::after {
    @include arrow(down, 10px, $popover-bg);

    bottom: -10px;
    content: ' ';
    position: absolute;
    right: 10px;
  }
}

@each $class, $colors in $message-colors {
  $bg: nth($colors, 1);
  $color: nth($colors, 2);

  @if ($class != default) {
    @include popover($class, $bg, $color);
  }
}

.popover-align-left {
  left: 0;
  right: auto;

  &::after {
    right: auto;
    left: 10px;
  }
}

v2-popover {
  position: absolute;
  transform-origin: bottom right;
  transition: transform .1s;
  top: 0;
  right: 0;

  &[align="left"] {
    transform-origin: bottom left;
    left: 0;
    right: auto;
  }

  &.ng-leave, &.ng-enter.ng-enter-active {
    transform: scale(1);
  }

  &.ng-leave.ng-leave-active, &.ng-enter {
    transform: scale(0);
  }
}
