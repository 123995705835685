.options {
  @include list-reset;
  @include baseline;

  &-item {
    @include flex-center;
    @include hover-type($header-color);

    font-size: $type-body;
    padding: padding(.5);
    padding-left: 0;
    position: relative;

    &:hover:not(.active) {
      position: relative;

      span {
        @include flex-center(justify);
      }

      span::before {
        @include circle(12px);

        animation: zoomIn .25s;
        background: $success-color;
        content: '';
        display: block;
      }
    }

    &.active {
      @include hover-type($header-color);

      span {
        background: $success-bg;
      }

      &::before {
        @include icon('\f00c');

        animation: zoomIn .25s;
        color: $success-color;
        font-size: 2em;
        z-index: 10;
      }
    }
  }

  &-item-button {
    @include circle(30px);

    background: $active-header-color;
    display: block;
    margin-right: 18px;
    position: relative;
    flex-shrink: 0;

    &-square {
      border-radius: 5px;
    }
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(.001);
  }

  100% {
    transform: scale(1);
  }
}
