.info-tags {
  display: inline-flex;
  margin-right: 5px;

  &__tag {
    display: none;
  }

  @media screen and (min-width: 1215px) {
    &__tag {
      display: inline-block;
      font-size: 12px;
      padding: 7px;
      color: rgba(255, 255, 255, 0.75);
      background: rgba(0, 0, 0, 0.1);
      margin: 0 1px;
      border-radius: 2px;
    }
  }

  @media screen and (min-width: 1543px) {
    &__tag {
      font-size: 14px;
    }
  }
}
