.treatment-summary {
  display: flex;

  @media screen and (max-width: 1400px) {
    flex-direction: column;

    .pricing-summary {
      margin-bottom: baseline(1);
    }
  }

  .tx-options {
    flex: 1;
  }
}
