.field-group {
  display: flex;
  margin-bottom: $baseline-height;
  padding-bottom: $baseline-height;

  &:last-child {
    border-bottom: 0;
  }

  &-action {
    flex: 0 0 $baseline-height * 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    &-icon {
      font-size: $baseline-height;
      color: $color-error;
    }
  }

  // Reset child field box so field group can handle margins
  > v2-field {
    flex: 1;

    .form-text-field-container {
      margin: 0;
    }
  }
}
