.body {
  color: $dark;
  font-size: 18px;
  margin-bottom: 18px;

  &--final {
    margin-bottom: 36px;
  }
}

.heading {
  font-size: 48px;
  font-weight: 300;
  color: $dark;
  margin: 0 0 18px;

  &--size-2 {
    font-size: 42px;
  }

  &--size-3 {
    font-size: 36px;
  }

  &--size-4 {
    font-size: 32px;
  }

  &--size-5 {
    font-size: 28px;
  }
}
