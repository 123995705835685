.success {
  @include clear-pressable;

  &-button {
    @include pill;

    color: $action-bg;
    display: flex;
    font-size: $type-subheader;
    padding: baseline(.5);
    align-items: center;
    justify-content: center;
    margin-right: baseline(0.5);
    flex: 1 1;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &-icon {
      margin-right: baseline(.5);
    }

    &-primary {
      background: $action-bg;
      color: $success-color;
      font-weight: bold;
    }

    &-secondary {
      border: 2px solid $action-bg;
      font-weight: 300;
    }

    &-group {
      position: absolute;
      bottom: baseline(1);
      z-index: 101;
      right: baseline(1);
      left: baseline(1);
      display: flex;
      justify-content: space-around;
    }
  }
}

.success-overlay {
  @include size(100%);
  @include flex(column);
  @include flex-center(justify);
  @include rounded-box($success-color);

  color: $action-bg;
  font-size: $type-header;
  position: absolute;
  z-index: 100;

  &--failed {
    background-color: hsla(50, 100%, 40%, 1);
  }

  .message-container > div {
    @include flex(column);
    @include flex-center;

    margin-top: $baseline-height;
  }

  > div {
    @include flex(column);
    @include flex-center(justify);

    flex-grow: 1;
  }

  img {
    width: 82px;
  }

  &.is-sticky {
    .message-container {
      justify-content: flex-start;
    }

    .message-block {
      position: sticky;
      position: -webkit-sticky;
      top: 300px;
      bottom: 200px;
    }
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .message {
    font-size: 32px;
    font-weight: 300;
    text-align: center;
  }

  .grow {
    flex-grow: 10;
    flex-shrink: 10;
  }

  .back {
    font-size: $type-body;
    color: $action-bg;
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: baseline(2);
  }

  .close {
    position: absolute;
    font-size: 1em;
    top: baseline(.5);
    right: $baseline-height;
    color: $action-bg;
    opacity: 1;
  }

  &__retry {
    margin: 0 auto;
    display: block;
    font-size: $type-body * 1.25;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    background-color:hsla(50, 100%, 30%, 1);
    outline: 0;
    border: none;
  }
}
