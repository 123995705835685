.buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;

    %button,
    .tertiary-button {
        margin-bottom: baseline(1);
    }

    @media screen and (max-width: 1369px) {
        align-items: flex-start;
        flex-direction: column;
    }

    &.center {
        align-items: center;
    }
}

%button {
    @include body;

    display: inline-block;
    padding: $padding-button ($padding-button * 2);
    text-align: center;
    border-radius: $border-radius;

    span {
        font-size: 0.7em;
        font-style: italic;
        margin: 0 5px;
    }
}

.primary-button {
    @extend %button;
    @include pressable(2px, $primary-color, 17.5%);
    @include hover-type($primary-button-color);

    font-weight: bold;
    padding-left: baseline(3);
    padding-right: baseline(3);
    position: relative;

    &--disabled {
        @include clear-pressable;
        background-color: $icon;
        transform: none;

        &:hover {
            transform: none;
        }
    }

    &__icon {
        position: absolute;
        right: baseline(1);
        top: 14px;
    }
}

.secondary-button {
    @extend %button;
    @include pressable(2px, $secondary-button-bg, 10%);
    @include hover-type($primary-color);
}

v2-next-button {
    min-width: 25%;

    .button {
        width: 100%;
    }
}

.button-add-patient {
    @include colorize(success);

    height: 100%;
    font-size: $type-body;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s background;

    &:hover {
        background: darken(get-message-bg($colors-success), 2%);
        text-decoration: none;
        transition: 0.2s background;
        color: get-message-color($colors-success);
        cursor: pointer;
    }

    &-icon {
        font-size: baseline(1);
        margin-right: baseline(0.5);
    }

    &:focus {
        color: get-message-color($colors-success);
    }
}

.reschedule-button {
    margin-top: 1em;
    margin-left: auto;
    margin-right: 0;
    display: block;
}
