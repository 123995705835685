#header {
  box-shadow: none;
}

.top-header {
  align-items: center;
  background: $primary-color;
  display: flex;
  height: 65px;

  .logo {
    width: 163px;
    justify-self: flex-start;
    margin-left: baseline(.5);

    &:hover {
      cursor: pointer;
    }

    img {
      max-width: 100%;
    }
  }

  .flex-span {
    flex-grow: 1;
  }

  .button-add-patient {
    width: 240px;
  }
}
