.contact-table {
  margin-bottom: baseline(2);
  width: 100%;

  td {
    border-bottom: 1px solid $border-color;
    font-size: $type-body;
    padding: baseline(.5) 0;
  }

  tr:first-child td {
    padding-top: 0;
  }

  a {
    color: $primary-color;
    text-decoration: underline;
  }
}
