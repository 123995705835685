.rescheduler {
    @include baseline;

    background: $action-bg;
    border-radius: $border-radius;
    transition: all 300ms ease;

    &__header {
        @include header($type-subheader);
        @include rounded-box($active-header-bg);

        position: relative;
        display: flex;
        color: $active-header-color;
    }

    &__header-icon {
        color: $active-header-color;
        font-size: 1.5em;
        margin-right: 0.5em;
    }

    &__close {
        color: $active-header-color;
        font-size: 1.5em;
        margin-left: auto;
        padding-left: 2em;
        margin-right: 0;
        transition: all 300ms ease;

        &:hover {
            color: $header-bg;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60rem;
        padding: $baseline-height $baseline-height * 2;
        margin-top: $baseline-height;
        overflow: hidden;
        position: relative;
    }

    &__success {
        width: 40vw;
        height: 40vh;
    }
}
