.pricing-summary {
  @include rounded-box($highlight-bg);
  @include flex(column);

  flex: 1;

  ul {
    @include list-reset;

    flex: 1;
  }

  li {
    @include baseline;

    font-size: 14px;
  }

  h5 {
    @include baseline;

    font-size: $type-body;
  }

  .pricing-non {
    @include baseline;
    flex: 1;

    a {
      color: $primary-color;
    }
  }
}
