.subsection {
  &-header {
    color: $non-primary-icon;
    padding: baseline(1) baseline(2);
    display: flex;
    align-items: center;
    font-size: $type-subheader;
    font-weight: bold;
    border: 1px solid $subsection-header-border-color;
    border-left: 0;
    border-right: 0;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0;

    &-text {
      flex: 1;
    }

    &-icon {
      margin-right: baseline(.5);
      height: 100%;
    }

    &-button {
      &:hover {
        cursor: pointer;
        color: $primary-color;
      }
    }
  }

  &-disabled-message {
    display: none;
  }

  &-body {
    padding: baseline(2);
    padding-top: baseline(1);
    transition: filter 0.2s;

    .is-disabled & {
      position: relative;
      filter: blur(3px);
      transition: filter 0.2s;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(#fff, 0.5);
        z-index: 5000;
      }

      &-disabled-message {
        display: block;
      }
    }
  }

  &.is-disabled {
    position: relative;

     .alert {
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
       z-index: 5001;
     }
  }
}
