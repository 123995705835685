.baseline {
  margin-top: baseline(1);
  margin-bottom: baseline(1);

  &-top {
    margin-top: baseline(1);
  }

  &-bottom {
    margin-bottom: baseline(1);

    @for $i from 2 through 5 {
      &-#{$i} {
        margin-bottom: baseline($i);
      }
    }
  }
}

.zero-margin {
  &-bottom {
    margin-bottom: 0;
  }
}
