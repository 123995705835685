.action-details {
  .note-component:last-child .note {
    border-bottom: 0;
  }

  .note {
    @include line($border-color);

    font-size: $type-small;

    &-highlighted {
      @include box($highlight-color, $highlight-bg);
    }

    &-title {
      color: $primary-color;
      margin-bottom: baseline(.5);
    }

    &-text {
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
}
