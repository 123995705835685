// Design System Colours
// ----

$dark: #111930;
$dark-highlight: #2c395d;

$light: #e1e3eb;
$light-bg: #f0f1f5;

$primary: #298bfa;
$primary-dark: #055fc5;

$shadow: #d9d9d9;

$icon: #8c8c8c;

// End Design System Colours

// Hue Cheatsheet
//
// Red     - 0
// Yellow  - 60
// Green   - 120
// Cyan    - 180
// Blue    - 240
// Magenta - 300
// Red     - 360

$main-bg: #f3f3f3;

$badge-bg: #f03a4b;
$primary-color: #298bfa;

$success-color: #3fad63;
$success-bg: #d4f8cf;

$warning-color: #ffcc00;
$warning-bg: lighten($warning-color, 40%);

// Header
$header-color: #111930;
$active-header-bg: #111930;
$active-header-color: #e1e3eb;
$header-bg: #fff;

// Nav
$nav-bg: #111930;
$nav-color: #fff;

// Search
$search-bg: #111930;
$search-color: #fff;

// Action

$action-bg: #fff;

// Upload Item BG

$upload-item-bg: #fff;

// Button

$primary-button-color: #fff;
$secondary-button-bg: lighten(#dadce4, 5%);

$border-color: #dadce4;
$field-border-color: #d6d7d8;

$shadow-color: hsla(0, 0%, 0%, .5);
$light-color: hsla(0, 100%, 100%, 1);

// Highlight

$highlight-bg: #f0f7ff;
$highlight-color: #6fb1fc;

// Info Box

$info-box-bg: #f8f6f7;
$info-box-color: #71717d;
$info-box-border-color: #dbdbdd;

$info-box-hover-bg: #eef2fb;
$info-box-hover-border-color: #a0a9f8;

// Subsections

$subsection-header-border-color: #dbdbdd;

// Non-primary

$non-primary-icon: #8c8c8c;

// Popover

$color-error: hsla(10, 100%, 40%, 1);

$colors-default: hsla(120, 0%, 40%, .75) #fff;
$colors-success: hsla(120, 100%, 40%, 1) #fff;
$colors-warning: hsla(50, 100%, 40%, 1) #fff;
$colors-error: $color-error #fff;

$message-colors: (
  default: $colors-default,
  success: $colors-success,
  warning: $colors-warning,
  error: $colors-error
);

// Text

$text-color: #3D4051;


/**
* V3
*/

$primary-light: #3F8CE4;

$dark-blue: #111930;

$mid-grey: #DADCE4;
$dark-grey: #5B5B5B;

$beige: #EDD7AB;

