// Typography

$baseline-height: 27px;

$type-title: 48px;
$type-header: 40px;
$type-subheader: 24px;
$type-body: 18px;
$type-small: 16px;

// Padding

$padding-button: $baseline-height / 3;
$padding-table: $baseline-height / 2;
$padding: $baseline-height;

// Borders

$border-radius: 6px;

// Info Box

$info-box-padding: 14px;

// Popover

$popover-padding: 10px;
