.tx-values {
  display: flex;

  @media screen and (max-width: 1390px) {
    flex-direction: column;

    .tx-value {
      margin-bottom: baseline(1);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .tx-value {
    flex: 1 1;
  }
}
