%form-field {
  border: 1px solid $field-border-color;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-size: $type-body;
  margin-bottom: $baseline-height;
  padding: padding(.5);
  width: 100%;
  box-shadow: inset 0 1px 0 $shadow;
}
