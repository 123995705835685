.action-form,
.form {
  label, &-label {
    display: block;
    font-size: $type-body;
    margin-bottom: baseline(.5);

    &.is-optional {
      display: flex;
      justify-content: space-between;
    }

    small {
      font-style: italic;
      font-weight: 300;
    }

    &-optional {
      font-weight: normal;
    }
  }

  &-text-field {
    @extend %form-field;

    &.has-icon {
      padding-left: baseline(2);
    }

    &-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      width: baseline(2);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: baseline(.75);
      color: $non-primary-icon;
      pointer-events: none;

      i {
        line-height: 1;
      }
    }
  }

  &-text-field-container {
    position: relative;
    margin-bottom: baseline(1);

    .form-text-field {
      margin-bottom: 0;
    }
  }

  &-date-field {
    @include icon-field('\f073', '.form-text-field');
  }

  &-money-field {
    @include icon-field('\f154', '.form-text-field');

    &::before {
      top: 23px;
    }
  }

  .tx-value, &-tx-value {
    @include header($type-header);
  }

  [placeholder]:empty::before {
    display: none;
  }
}
