.body-wide .nav-bar {
  display: none;
}

.app>.main-container>.content-container {
  margin-left: 162px;

  &--v3 {
    margin-left: 224px;
    position: relative;
  }
}

.nav-bar {
  background: $nav-bg;
  bottom: 0;
  position: fixed;
  top: 54px;
  width: 162px;

  &--v3 {
    width: 224px;
    height: calc(100vh - 54px);
  }

  &-item {
    @include hover-type($nav-color, $primary-color);

    color: $nav-color;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 300;
    padding: 18px 0;
    text-transform: initial;
    transition: .2s background, .2s color;
    align-items: center;
    position: relative;

    &.is-active {
      color: $primary-color;
    }

    &:hover {
      background: darken($nav-bg, 10%);
      transition: .2s background, .2s color;
    }

    &:focus {
      color: $primary-color;
    }

    &::after {
      opacity: 0;
      content: '';
    }

    &.is-active::after {
      @include arrow(left, 18px, $main-bg);

      content: '';
      opacity: 1;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: .5s opacity;
    }
  }

  &-icon {
    font-size: 24px;
    display: block;
  }
}
