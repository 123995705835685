gdpr-checkbox {
  width: 100%;
}

.gdpr-checkbox {
  @extend .message-warning;

  padding: 0 baseline()/2;
  width: 100%;

  &-hint {
    font-size: 1.1em;
    font-weight: normal;
    position: relative;
    color: #666;

    em {
      font-size: 0.9em;
    }

    small {
      position: relative;
    }
  }

  md-checkbox .md-label {
    width: auto;
  }
}
