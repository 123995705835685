.bg-primary {
  background: #75859b;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar),
md-toolbar:not(.md-menu-toolbar) {
  background: #75859b;
}

.app > .header-container.header-fixed {
  z-index: 550;
}

.search-box patient-search {
  position: relative;

  md-input-container:not(.md-input-invalid).md-input-focused label {
    color: transparent;
  }

  md-autocomplete[md-floating-label] md-autocomplete-wrap {
    height: 32px;
  }

  md-input-container {
    padding: 0;
    margin: 0;
  }

  md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label,
  md-input-container:not(.md-input-invalid).md-input-has-value label {
    color: transparent;
  }

  md-input-container .md-input {
    color: #fff;
    border: 0;
    background: rgba(0, 0, 0, 0.1);
    padding-left: 8px;
  }

  md-input-container label {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8em;
    bottom: 103%;
    left: 5px;
  }

  md-icon {
    height: auto;
    margin: 0;
    padding: 0;

    i {
      color: #fff;
      margin-right: 10px;
    }
  }

  .highlight {
    color: #009688;
  }
}

.header-container>.top-header .top-nav .search-box .input-group-addon {
  padding: 8px 12px;
}

.header-container>.top-header .top-nav .nav-left>li {
  padding-top: 15px;
}

.page-signin .body-inner::before,
.page-signup .body-inner::before,
.page-forgot .body-inner::before {
  background-color: #75859b;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 151;
}

.patient-search-menu li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.patient-search-menu li:last-child {
  border-bottom-width: 0;
}

.patient-search-menu .item-title,
.patient-search-menu .item-metadata {
  display: block;
  line-height: 2;
}

.patient-search-menu .item-metadata {
  font-size: 0.9em;
}

.patient-search-menu .item-title md-icon {
  height: 18px;
  width: 18px;
}
