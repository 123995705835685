%details-box-inner {

  flex: 1 1;
  margin-right: $baseline-height;

  @media screen and (max-width: 1400px) {
    margin-right: 0;
    margin-bottom: baseline(1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  > div {
    @include flex-align(center);
    @include rounded-box($info-box-border-color, $info-box-bg, $info-box-padding);
    @include hover-type($info-box-color);

    color: $info-box-color;
    flex: 1;
    position: relative;
    text-align: left;
  }

  span {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &:hover > div {
    @include rounded-box(
      $info-box-hover-border-color,
      $info-box-hover-bg,
      $info-box-padding
    );

    cursor: pointer;
  }
}

.details-box {
  @include baseline;
  @include flex-align(stretch);

  margin-bottom: baseline(2);

  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }

  &-icon {
    @include size(14px);

    font-size: 21px;
    line-height: 0.75;
    margin-left: 10px;

    svg {
      fill: $info-box-color;
    }

    &-email {
      width: 18px;
      height: 14px;
    }

    &-phone {
      width: 17px;
      height: 15px;
    }

    &-n-1-square {
      height: 21px;
    }

    &-key {
      height: 21px;
    }

    &-email, &-phone, &-n-1-square, &-key {
      margin-right: baseline(.5);
    }
  }

  &-email {
    @extend %details-box-inner;
  }

  &-phone {
    @extend %details-box-inner;
    margin-right: 0;
  }

  &-item {
    @extend %details-box-inner;

    &:last-child {
      margin-right: 0;
    }
  }
}
