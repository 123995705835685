.alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: baseline(1);

  &-center {
    justify-content: center;
  }

  &-icon {
    font-size: baseline(2);
    color: $non-primary-icon;

    &-spin {
      animation: 1s infinite spin;
    }

    &-bounce {
      animation: 1s infinite bounce-top alternate;
    }
  }

  &-message {
    font-weight: 300;
    text-align: center;

    &-header {
      font-size: $type-header;
      color: $primary-color;
    }

    &-text {
      font-size: $type-subheader;
    }
  }
}
