.note .zmdi {
  width: 64px;
  height: 64px;
  font-size: 36px;
}

.note {
  margin-bottom: 10px;
}

#patient-form-container, .leadflo-action {
  md-toolbar .md-button {
    color: #fff;
  }
}

.layout-padding > next-action {
  padding: 0;
}

.layout-padding > next-action > * {
  padding: 8px;
}

[contenteditable] {
  border-bottom: 1px dotted #ccc;
  outline: 1px solid transparent;
}

span[contenteditable] {
  display: inline-block;
}

h4[contenteditable] {
  margin-bottom: 0.5em;
}

[placeholder]:empty:before {
  cursor: text;
}

[placeholder]:empty:before {
  content: attr(placeholder);
  color: #aaa;
}

[placeholder]:empty:focus:before {
  content: attr(placeholder);
}

[placeholder]:focus:before {
  content: '';
}

md-input-container .md-errors-spacer {
  min-height: 0;
}

md-checkbox .md-label {
  width: 100%;
}

md-dialog-content md-content {
  &.odd {
    background: #f1f1f1;
  }

  &.selected {
    background: rgb(0,150,136);
    color: #fff;
  }

  &.selected.odd {
    background: darken(rgb(0,150,136), 5%);
  }

  md-checkbox {
    margin-bottom: 0;
  }
}

md-toolbar {
  transition: background 0.5s;
}

.action-implant {
  md-toolbar, .md-button.md-primary.md-raised {
    background: rgb(0,150,136);
  }
}

.action-dentures {
  md-toolbar, .md-button.md-primary.md-raised {
    background: #AD3B9C;
  }
}

.action-ortho {
  md-toolbar, .md-button.md-primary.md-raised {
    background: rgb(0, 93, 150);
  }
}

.action-cosmetic {
  md-toolbar, .md-button.md-primary.md-raised {
    background: #985AE3;
  }
}

.action-general {
  md-toolbar, .md-button.md-primary.md-raised {
    background: rgb(128, 147, 158);
  }
}

.md-select-menu-container {
  z-index: 10000;
}

.md-inline-form md-datepicker {
  margin-top: 0;
}

md-chips .md-chips {
  box-shadow: none;
}

.md-chips .md-chip {
  font-size: 0.8em;
  border-radius: 3px;
  background: #f1f1f1;
  color: #666;
}

.leadflo-action {
  position: relative;
}

.circle-stats div {
  text-align: center;
}

.circle-stats span {
  display: block;
  background: #eee;
  text-align: center;
  width: 100px;
  height: 100px;
  font-size: 4em;
}

.timeline {
  list-style: none;
  padding: 0;
}

.timeline li {
  margin-bottom: 30px;
  display: flex;
  align-items: stretch;
  position: relative;
}

.timeline li:last-child {
  margin-bottom: 0;
}

.timeline li::before {
  border-left: 1px solid #ccc;
  content: '';
  position: absolute;
  top: 100%;
  left: 4px;
  height: 30px;
}

.timeline li:last-child::before {
  display: none;
}

.timeline .icon-container {
  display: flex;
  align-items: center;
}

.timeline i {
  width: 30px;
  color: #005d96;
}

.timeline h3 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #005d96;
}

.timeline h3 span {
  font-size: 0.9em;
  font-weight: normal;
  font-style: italic;
  color: #666;
}

.timeline div h3:last-child {
  margin-bottom: 0;
}

.timeline p {
  margin-bottom: 0;
}

.patient-form-message {
  background: #eee;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 24px;
  border-radius: 5px;
  color: #999;

  div {
    text-align: center;
  }
}
