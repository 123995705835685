.countdown {
  @include flex-align(center, flex-start);

  flex: 1 1;
  font-size: 2em;

  &-component {
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    margin-right: 20px;
    overflow: hidden;
    text-align: center;
  }

  &-label {
    background: $badge-bg;
    color: #fff;
    font-size: 0.5em;
    letter-spacing: 2px;
    padding: 10px;
    text-transform: uppercase;
  }

  &-value {
    @include flex-align(center, center);

    background: #fff;
    font-size: 2em;
    font-weight: bold;
  }

  &-digit {
    border-right: 1px solid $border-color;
    padding: 0 20px;

    &:last-child {
      border: 0;
    }
  }
}
