.toggle-field {
  display: flex;
  font-size: $type-body;
  align-items: center;
  user-select: none;

  .toggle-field-control {
    @include pill(5px);

    padding: 5px;
    display: flex;
    align-items: center;
    min-width: 100px;
    font-weight: bold;
    margin-right: baseline(1);

    &::before {
      @include circle(25px);

      display: block;
      content: '';
      background: get-message-color($colors-error);
    }

    &.is-on {
      @include colorize(success);

      flex-direction: row-reverse;
    }

    &.is-off {
      @include colorize(error);
    }

    &-text {
      text-align: center;
      flex-grow: 1;
    }
  }

  &-text {
    font-style: italic;
    font-weight: 300;
  }
}
