.comm-modal {
    display: flex;
    flex-direction: column;

    &__header {
        width: 100%;
        padding: 4rem;
        background-color: $header-color;
        border-radius: 10px;
    }

    &__header-line {
        color: white;
        font-size: 2rem;
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
    }

    &__header-key {
        font-weight: 700;
        width: 10%;
        display: block;
        text-align: right;
        margin-right: 1em;
    }
    
    &__header-value {
        font-weight: 300;
    }

    &__status-light {
        margin-left: 0.5rem;
        font-size: 1rem;
        vertical-align: middle;
    }

    &__body {
        padding: 4rem;
        font-size: 2rem;
    }

    &__loading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}