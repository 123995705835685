.notice-text {
  @include header($type-subheader);

  > div {
    @include baseline;
    margin-bottom: baseline() * 1.5;
  }

  h4 {
    @include baseline;
    @include header($type-header);

    color: #7c7f88;
  }

  &.callback-text h4 {
    color: $header-color;

    img {
      margin-right: 18px;
    }
  }

  &.callback-text p {
    margin-bottom: baseline(1);
  }

  ul {
    font-size: .9em;
    list-style: none;
    margin: 0;
    padding: 0;


    li {
      margin: .5em 0;
      padding-left: baseline();
      position: relative;

      &::before {
        @include icon('\f105');
        color: $primary-color;
        left: 0;
      }
    }
  }
}
