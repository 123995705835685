.color-field {
  display: flex;

  &__color {
    width: 25%;
    margin-left: $baseline-height;
    margin-bottom: $baseline-height;
    margin-top: $baseline-height + 13px;
    border-radius: 5px;
    flex: 1 1;
    transition: background .2s;
    border: 1px solid $field-border-color;
  }
}
