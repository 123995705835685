.section {
  @include baseline;

  background: $action-bg;
  border-radius: $border-radius;
  position: relative;

  &.is-pressable {
    @include pressable(2px, $action-bg);
  }

  &.is-open {
    &.is-pressable {
      @include disable-pressable(2px, $action-bg);
    }

    .section-header {
      background: transparent;
      color: $active-header-color;

      i {
        color: $active-header-color;
      }
    }
  }

  &-header {
    @include header($type-subheader);
    @include rounded-box($header-bg);

    margin-bottom: 0;

    &--with-icon {
      padding-left: padding(3);
    }

    position: relative;
    line-height: 1.5;
    margin-top: 0;

    &-icon {
      @include vertical-center;

      color: $primary-color;
      font-size: 1.5em;
      left: $baseline-height;
    }
  }

  &-body {
    padding: baseline(2);
    position: relative;
  }

  a:hover {
    cursor: pointer;
  }
}

.overflow-protector {
  overflow: hidden;
}
